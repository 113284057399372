// initializes cart and fetches total price from the backend

export default defineNuxtRouteMiddleware(async to => {

    const nuxtApp = useNuxtApp();

    if (process.client) {

        const currentCartItems = useCart().items.value;
        const cookieCartItems = useCart().getItems();

        // cart items has changed from the other browser tab or the cookie has expired, reload cart items
        if (!useUtils().isDeepEqual(currentCartItems, cookieCartItems)) {
            await useCart().reloadItems();
        }
    }
})