import { useShopRouting } from "./useShopRouting";

export const useLocale = () => {

    const formatPrice = (value: number, freeLabelAllowed : Boolean = false, localeObj : Object = undefined) => {
        if (value === 0 && freeLabelAllowed) {
            return useI18n().t('Zdarma');
        }

        localeObj = localeObj ?? getLocale();

        const formatter = new Intl.NumberFormat(localeObj.locale, {
            style: 'currency',
            currency: localeObj.currencySign,
            minimumFractionDigits: localeObj.minDigits,
            maximumFractionDigits: localeObj.maxDigits
        });

        return formatter.format(value);
    }

    const getLocalesForCurrentShopZone = () => {
        const sz = useShopRouting().getShopZone()!
        return sz.locales;
    }

    const getLocale = () => {

        const availableLocales = getLocalesForCurrentShopZone();

        const defaultLocaleId = availableLocales[0];
        const cookieLocaleId = useShopCookie().read('locale');

        const result = availableLocales.find(q => q.id.toString() == cookieLocaleId) ?? defaultLocaleId;

        return result;
    };

    const setLocale = (id: Number) => {
        useShopCookie().set('locale', id.toString());
    };

    return {
        formatPrice,
        getLocalesForCurrentShopZone,
        getLocale,
        setLocale
    }
}