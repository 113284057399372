<template>
    <span class="align-middle" :class="[`icon-Ikona_${props.icon}`, `text-${props.size}`, { 'p-2 rounded-full text-white bg-conversion' : props.filled }, props.class]"></span>
</template>
<script setup>

const props = defineProps({
    icon: String,
    filled: Boolean,
    class: String,
    size: String
});

</script>