export const useFbAPI = () => {

  const registerServerEvent = async (eventName, analyticItems, orderId) => {

    if (!process.client) {
      return null;
    }

    const fbEventResponse = (await $fetch(`/api/mutations/fbEventId?currencyId=${useLocale().getLocale().currencyId}`, {
      method: 'POST',
      body: {
        type: eventName,
        userAgent: navigator.userAgent,
        eventSourceUrl: document.location.toString(),
        products: analyticItems.map(item => ({
          id: item.item_id.toString(),
          itemPrice: item.price,
          quantity: item.quantity || 1
        })),
        idOrder: orderId
      }
    })).external.facebook.sendEvent;

    if (fbEventResponse?.state && fbEventResponse.eventId) {
      useAnalytics().push({ fb_event_id: fbEventResponse.eventId });
      return fbEventResponse.eventId;
    }
    else {
      return null;
    }

  }

  return {
    registerServerEvent
  }

}