export default defineNuxtRouteMiddleware(async (to, from) => {

  // only hash has been changed - it is used for filtering purposes, not for routing, so do not trigger analytics route changed event
  if (from.path === to.path && JSON.stringify(from.query) === JSON.stringify(to.query) && (from.hash != to.hash)) {
    return;
  }

  // close all dialogs, enable page scrolling
  if (process.client) {

    const pageData = usePage().value.page;

    if (pageData) {

      const locale = useLocale().getLocale();
      const shopZone = useShopRouting().getShopZone();

      const isMagazineArticle = pageData.entity?.kind === 2 && !!pageData.entity?.magazineCategory;

      useAnalytics().push({
        site: shopZone?.domain,
        page_category: isMagazineArticle ? 'Magazín a slovník' : pageData.module.pageCategoryLabel,
        page_type: isMagazineArticle ? 'Článek v magazínu' : pageData.module.pageTypeLabel,
        page_location: `${document.location.origin}${to.fullPath}`,
        page_title: pageData.title,
        currency: locale.currencySign,
        language: locale.locale
      });

      useAnalytics().push({
        event: 'routeChanged'
      });
    }
  }

});