export const useLikedWares = () => {
  
  const items = () => useState('likedWareIds', () => useShopCookie().read('likedWareIds')?.toString().split(',').filter(item => item).map(item => parseInt(item)) || []);

  const isLiked = (wareId: number) => computed(() => getLikedWareIds().includes(wareId));

  const getLikedWareIds = () => items().value;

  const addToList = (wareId: number) => {
    if (!isLiked(wareId).value) {

      const newList = getLikedWareIds();

      newList.push(wareId);

      useShopCookie().set('likedWareIds', newList.join(','));
    }
  }

  const removeFromList = (wareId: number) => {
    if (isLiked(wareId).value) {

      let newList = getLikedWareIds();
      const index = newList.indexOf(wareId);

      newList.splice(index, 1);

      if (newList.length) {
        useShopCookie().set('likedWareIds', newList.join(','));
      }
      else {
        useShopCookie().remove('likedWareIds');
      }
    }
  }

  const toggle = (wareId: number) => {
    if (isLiked(wareId).value) {
      removeFromList(wareId);
      return false;
    }
    else {
      addToList(wareId);
      return true;
    }
  }

  return {
    isLiked,
    getLikedWareIds,
    addToList,
    removeFromList,
    toggle
  }

}