import { default as _91_46_46_46slug_93hJ5SifnYcdMeta } from "C:/BuildAgent/work/7992c122409a32d2/code/fe-app/pages/[...slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93hJ5SifnYcdMeta?.name ?? "slug",
    path: _91_46_46_46slug_93hJ5SifnYcdMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93hJ5SifnYcdMeta || {},
    alias: _91_46_46_46slug_93hJ5SifnYcdMeta?.alias || [],
    redirect: _91_46_46_46slug_93hJ5SifnYcdMeta?.redirect || undefined,
    component: () => import("C:/BuildAgent/work/7992c122409a32d2/code/fe-app/pages/[...slug].vue").then(m => m.default || m)
  }
]