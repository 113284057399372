<template>
    <div>
        <component :is="headerType" class="py-8 font-secondary" :class="[{ 'text-2xl lg:text-4xl' : !props.size }, props.class, props.size]">
            <slot></slot>
        </component>
    </div>
</template>
<script setup>

const props = defineProps({
    class: String,
    type: Number,
    size: String,
    withLine: Boolean
});

const headerType = 'h' + (props.type ?? 1);

</script>
<style lang="postcss">
.header--with-line {
    @apply after:content-[''] after:block after:max-w-[200px] after:pt-8 after:border-b-4 after:border-secondary;
}

.header--with-inverted-line {
    @apply after:content-[''] after:block after:max-w-[200px] after:pt-8 after:border-b-4 after:border-white;
}
</style>