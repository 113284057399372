export function useShopCookie() {

    const getFullCookieName = (cookieName: String) => `${useShopRouting().getShopZone()?.domain}-${cookieName}`;

    const read = (cookieName: String) => {

        const fullName = getFullCookieName(cookieName);
        const cookie = useCookie(fullName, {
            watch: false
        });

        if (typeof cookie.value !== 'undefined') {
            return cookie.value;
        }
        else {
            return null;
        }
    }

    const set = (cookieName: String, value: String) => {
        const fullName = getFullCookieName(cookieName);
        var expires = "";

        var date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();

        document.cookie = fullName + "=" + (value || "") + expires + "; path=/";
    }

    const remove = (cookieName: String) => {
        const fullName = getFullCookieName(cookieName);

        document.cookie = fullName + '=; Max-Age=-99999999;';
    }

    return {
        read,
        set,
        remove
    }
}