<template>
    <NuxtLayout name="catalog">
        <div class="page-container mt-4 mb-12">
            <AtomsTextHeader class="uppercase">{{ $t('500 - Chyba na stránce') }}</AtomsTextHeader>
            <p>Na stránce se vyskytla chyba, o které byli informováni vývojáři. Zkuste prosím stránku načíst znovu. Děkujeme za pochopení.</p>
            <p v-if="errorId" class="mt-4">Pokud by se chyba opakovala, prosíme kontaktujte nás a sdělte ID chyby: <strong>{{ errorId }}</strong></p>
            <TemplatesNotFound />
        </div>
    </NuxtLayout>
</template>
<script setup>

const appConfig = useAppConfig();
const error = useError().value;

console.log(error);

const errorId = ref('');

if (process.client) {
    errorId.value = (await $fetch(appConfig.errorLogging.serviceEndpointUrl, {
        method: 'POST',
        body: {
            Subject: error.message,
            Severity: 3,
            Text: `
            <h2>Error object</h2>
            <table>
                <tr><td><strong>URL</strong></td><td>${useRoute().fullPath}</td></tr>
                <tr><td><strong>Name</td><td>${error.name ?? ''}</td></tr>
                <tr><td><strong>Message</td><td>${error.message}</td></tr>
                <tr><td><strong>Cause</td><td>${error.cause ?? ''}</td></tr>
                <tr><td><strong>Description</td><td>${error.description ?? ''}</td></tr>
            </table>
            <hr />
            <h2>Stack trace</h2>
            ${error.stack}
            <hr />
            <h2>Data</h2>
            <pre>${error.data ?? ''}</pre>
            <hr />
            <h2>User data</h2>
            <table>
                <tr><td><strong>Cookie</strong></td><td>${document.cookie}</td></tr>
                <tr><td><strong>Browser</strong></td><td>${navigator.userAgent}</td></tr>
                <tr><td><strong>Language</strong></td><td>${navigator.language}</td></tr>
                <tr><td><strong>Languages</strong></td><td>${navigator.languages.join(', ')}</td></tr>
            </table>`
        },
        headers:
        {
            'x-api-key': appConfig.errorLogging.apiKey
        }
    })).idEvent;
}

</script>