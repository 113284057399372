<template>
  <div>
    <AtomsTextHeader type="2" class="text-xl lg:text-2xl">{{ $t('Nevíte si rady s výběrem? Rádi Vám pomůžeme!') }}</AtomsTextHeader>
    <MoleculesButtonsButton :arrow-right="true" @click="search">{{ $t('Vyhledejte si svůj vysněný prsten') }}</MoleculesButtonsButton>

    <div class="flex flex-wrap gap-4 mt-16">
      <div>
        <AtomsImagesIcon icon="phone" size="xl" class="mr-4" />+420 773 542 416
      </div>
      <div class="border-r-2 border-secondary"></div>
      <div>
        <AtomsImagesIcon icon="mail" size="xl" class="mr-4" />info@snubniprsteny4u.eu
      </div>
    </div>
  </div>
</template>
<script setup>

const search = () => {
  useState('isSearchOpen', () => false).value = true;

  if (process.client) {
    nextTick(() => {
      document.getElementById('txtSearch').focus();
    });
  }
}

</script>