// fetches page data and saves it to the state for all components
// page data retrieval is executed only on path change - it ignores query that is managed/watched by the component itself

export default defineNuxtRouteMiddleware(async (to, from) => {

    // only hash has been changed - it is used for filtering purposes, not for routing, so cancel data refetching
    if (from.path === to.path && JSON.stringify(from.query) === JSON.stringify(to.query) && (from.hash != to.hash)) {
        return;
    }

    // close all dialogs, enable page scrolling
    if (process.client) {
        document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
        useCart().addedToCartDialog.value.show = false;
    }

    const nuxtApp = useNuxtApp();

    if (process.client) {
        if (to.query.cache === 'false') {
            useShopCookie().set('cacheDisabled', 'true');
        }
        else if (to.query.cache === 'true') {
            useShopCookie().remove('cacheDisabled');
        }
    }

    // disable double fetching on regular hydratation on the client with the state from the server
    if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
        return;
    }

    const shopZone = useShopRouting().getShopZone()!;
    const locale = useLocale().getLocale();
    const pageData = usePage();

    let url = to.path.substring(1) || 'home';

    // initial load or path change
    if (!pageData.value || pageData.value.url !== url) {

        const query = {
            url: url,
            shopZoneId: shopZone.shopZoneId,
            languageId: locale.languageId,
            currencyId: locale.currencyId
        }

        if (to.query.id && to.query.s) {
            query.id = to.query.id;
            query.s = to.query.s;
        }

        const result = (await useApiFetch('/api/page', {
            query: query
        }));

        await useAvailability().loadFreeDays();

        if (result.error.value) {
            throw createError({ statusCode: 404, statusMessage: 'Page Not Found', fatal: true });
        }
        else {
            pageData.value = result.data.value;
        }
    }
});