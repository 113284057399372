import validate from "C:/BuildAgent/work/7992c122409a32d2/code/fe-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45route_45global from "C:/BuildAgent/work/7992c122409a32d2/code/fe-app/middleware/01.route.global.ts";
import _02_45auth_45global from "C:/BuildAgent/work/7992c122409a32d2/code/fe-app/middleware/02.auth.global.ts";
import _03_45cart_45global from "C:/BuildAgent/work/7992c122409a32d2/code/fe-app/middleware/03.cart.global.ts";
import _04_45analytics_45global from "C:/BuildAgent/work/7992c122409a32d2/code/fe-app/middleware/04.analytics.global.ts";
export const globalMiddleware = [
  validate,
  _01_45route_45global,
  _02_45auth_45global,
  _03_45cart_45global,
  _04_45analytics_45global
]
export const namedMiddleware = {}