<template>
    <component :is="componentType" :to="props.href" @click.prevent="click" class="button" :class="[props.class, { 'button--ghost': props.ghost, 'button--conversion': props.type === 'conversion', 'button--small': props.small, 'button--secondary': props.secondary }]">
        <AtomsImagesIcon v-if="props.arrowLeft" icon="sipkaDoleva" class="button__arrow button__arrow--left" />
        <AtomsImagesIcon v-if="props.icon" :icon="props.icon" />
        <div class="flex-1">
            <slot></slot>
        </div>
        <AtomsImagesIcon v-if="props.arrowRight" icon="sipka" class="button__arrow button__arrow--right" />
    </component>
</template>
<script setup>

const props = defineProps({
    type: String,
    href: String,
    dummy: Boolean,
    forceReload: Boolean,
    icon: String,
    arrowLeft: Boolean,
    arrowRight: Boolean,
    ghost: Boolean,
    small: Boolean,
    secondary: Boolean,
    class: String
});

const emits = defineEmits(['click']);

const click = () => {

    if (props.href && props.forceReload) {
        document.location = props.href;
    }
    else {
        emits('click');
    }
}

const componentType = computed(() => {
    if (props.href) {
        return resolveComponent('NuxtLink');
    }
    else if (props.dummy)
    {
        return 'span';
    }
    else {
        return 'button';
    }
});

</script>
<style lang="postcss">
.button__arrow {
    display: inline-block;
    font-weight: normal;
    transition: all ease-in-out 0.15s;
}

.button__arrow--left {
    @apply mr-3 ml-0 lg:mr-4;
}

.button__arrow--right {
    @apply ml-3 mr-0 lg:ml-4;
}

.button:hover .button__arrow--left {
    transform: translateX(-5px);
}

.button:hover .button__arrow--right {
    transform: translateX(5px);
}
</style>