let cachedApiFetch = [];

export async function useApiFetch(url: String, options: Object | undefined) {

  const withoutApi = useRequestEvent()?.headers?.has('apiDisabled');
  let key = '';
  
  if (withoutApi) {
    key = url + '-' + JSON.stringify(options ?? {}).replaceAll('\n', '').replaceAll('\t', '');
    if (cachedApiFetch[key]) {
      //console.log( key + 'hit');    
      return cachedApiFetch[key];
    }
  }

  const result = useFetch(url, options);

//console.log( key + 'not hit');

  if (withoutApi) {
    cachedApiFetch[key] = result
  }

  return result;
}